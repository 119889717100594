/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import { getCookie } from '@shein/common-function'
import UserKey from '../UserKey'
import { COOKIE_KEY } from './lite/MemberIdLite'

class MemberId extends UserKey {
  static canHandle(key) {
    return key === COOKIE_KEY
  }

  /**
   * 同步获取 cookie 中的 memberId
   */
  get () {
    return getCookie(this.key) || ''
  }
}

export default MemberId
