/**
 * 职责： 用于收集埋点数据
 */

import { collectDataAssign } from './utils' 
class CollectData {
  constructor(config) {
    this.isStart = false // 开始记录状态
    this.sessionId = null
    this.onWatch = () => {}
    this.data = {}
    this.onceData = {}
    this.currentPvData = null // 当前pv的数据
    this.config = config
    this._init()
  }

  _init() {
    const { saNames, singleKey, saNameDefaultLimit, handleAction, timestampLength } = this.config
    this.saNameMap = this.initSaNameMap(saNames)
    this.saNameDefaultLimit = saNameDefaultLimit || 30 // 默认限制30条
    this.singleKey = singleKey || false
    this.handleAction = handleAction || ((v) => v)
    this.timestampLength = timestampLength
  }

  initSaNameMap(saNames = []) {
    if (!Array.isArray(saNames)) {
      return { list: [], moreNames: [] }
    }
    const moreNames = []
    const list = []
    saNames.forEach(item => {
      if (typeof item === 'string') {
        item = { saName: item, limit: this.saNameDefaultLimit }
      }
      item.saName.includes(',') ? moreNames.push(item) : list.push(item)
    })
    return { list, moreNames }
  }

  getData() {
    const data = collectDataAssign(this.data, this.onceData || {})
    this.onceData = {}
    return data
  }

  // 初始化数据时，设置数据
  setInitData(value = {}) {
    this.data = Object.assign({}, value)
    let keys = Object.keys(this.data)
    if (keys.length === 0) return
    if (!this.singleKey) {
      keys = keys.sort((a, b) => b - a) // 降序
    }
    this.sessionId = keys[0]
    this.isStart = true
  }

  registerHandler({ watch }) {
    this.onWatch = watch
  }

  /**
   * @description: 对外暴露的开始方法，每次路由跳转时命中目标路由时调用
   * */
  startCollect(route) {
    this.isStart = true
    if (!this.sessionId) {
      this.sessionId = Date.now()
    } else if (!this.singleKey) {
      this.handleMoreSessionIdData(route)
    }

    if (!this.data[this.sessionId]) {
      const initData = { 
        _fromPv: this.currentPvData // 上个页面的pv数据
      }
      if (route) {
        const { to, from } = route
        initData._routeData = {
          to: {
            name: to.name,
            query: to.query,
            params: to.params,
            path: to.path
          },
          from: {
            name: from.name,
            query: from.query,
            params: from.params,
            path: to.path
          }
        }
      }
      this.data[this.sessionId] = initData
    }
  }

  /* 处理多sessionId数据生成 */
  handleMoreSessionIdData({ to, from }) {
    // 判断当前路由是否有在之前记录中：
    // 1. 如果没有则重新生成
    // 2.如果有则用之前的sessionId，
    //   （1）找到之前的sessionId,并判断是否是在最后一项的生成顺序中，如果在 那就直接使用。
    //    如果不在最后一项，判断是返回后的  还是新创建的页面。
    const sessionIds = Object.keys(this.data)
    const isGenarate = sessionIds.some(
      sid => this.data[sid]?._routeData?.to?.path === to.path
    )
    // 如果没有找到则重新生成
    if (!isGenarate) {
      this.sessionId = Date.now()
      return
    }

    //  判断是否是在最后一项的生成顺序中
    const lowerSortSessionIds = sessionIds.sort((a, b) => b - a)
    const startSessionId = lowerSortSessionIds[0]
    if (this.data[startSessionId]?._routeData?.to?.path === to.path) {
      this.sessionId = startSessionId
      // this.handleResetSessionIdData()
      return
    }

    // 此时创建的是在中间的id， 判断是返回后的  还是新创建的页面
    const index = lowerSortSessionIds.findIndex((sid) => this.data[sid]?._routeData?.to?.path === to.path) // 会存在重复记录目标，取最近一次
    const backSessionId = lowerSortSessionIds[index]
    const currentData = this.data[backSessionId]
    if (currentData._routeData.to.path === to.path && currentData._routeData.from.path === from.path) {
      // 此时命中 记录一模一样的，说明就是新创建的页面
      this.sessionId = Date.now()
    } else {
      // 页面进行返回后时触发
      this.sessionId = backSessionId
      const currentCollectData = lowerSortSessionIds.slice(0, index + 1).reduce((obj, k) => {
        obj[k] = this.data[k]
        return obj
      }, {})
      this.data = currentCollectData
      // this.handleResetSessionIdData()
    }
  }

  // 重置当前session数据
  // handleResetSessionIdData() {
  //   const currentSessionIdData = this.data[this.sessionId] || {}
  //   this.data[this.sessionId] = {
  //     _routeData: currentSessionIdData._routeData,
  //     _fromPv: this.currentPvData
  //   }
  // }

  /**
   * @description: 对外暴露的结束方法
   * */
  endCollect(options = {}) {
    if (options?.keep) { // 为true继续收集
      // 存储之前的key
      this.data = Object.keys(this.data).reduce((o, k) => {
        o[k] = {}
        return o
      }, {})
    } else {
      this.isStart = false
      this.data = {}
    }
    this.onWatch(this.data)
  }

  // 获取当前埋点的单个数据和多个数据
  getSaName(saData) {
    if (!this.isStart || !(this.saNameMap?.list?.length || this.saNameMap?.moreNames?.length || !saData?.activity_name)) {
      return {}
    }
    const saName = this.saNameMap.list.find(item => item.saName === saData.activity_name) // 单个埋点
    const moreSaName = this.saNameMap.moreNames.find(item => item?.saName?.split(',')?.includes(saData.activity_name)) // 多个埋点
    return { saName, moreSaName }
  }

  // 处理超过限制的数据
  handleSaNameLimitData({ saName, moreSaName }) {
    const currentData = this.data[this.sessionId]
    const setSaNameLimitData = ({ item }) => {
      if (!item) return
      const limit = item.limit || this.saNameDefaultLimit
      const currentActivityData = currentData[item.saName] || []
      if (currentActivityData.length >= limit) {
        currentActivityData.shift() // 超过限制，删除第一条
      }
    }
    ;[moreSaName, saName].forEach(item => setSaNameLimitData({ item }))
  }

  /**
   * @description: 埋点触发
   * */
  trigger(value) {
    this.handleSetPvData(value) // 设置pv数据
    this.handleSetData(value, this.data)
  }

  handleSetPvData(value = {}) {
    if (value.activity_name !== 'page_view') return
    this.currentPvData = {
      page_name: value.page_name,
      page_param: value.page_param,
      timestamp: value.timestamp,
      end_time: value.end_time
    }
  }

  onceSet(value = {}, { to, from }) {
    if (!Object.keys(this.data || {}).length && to && from) {
      this.startCollect({ to, from })
    }
    if (!this.onceData[this.sessionId]) {
      this.onceData[this.sessionId] = {}
    }
    const id = Math.random().toString(36).slice(6) // 生成唯一id
    value.activity_param = Object.assign({}, value.activity_param, { _actionId: id })
    this.handleSetData(value, this.onceData)
    return id
  }

  handleSetData(value, result) {
    const objData = result[this.sessionId]
    if (!objData) return // 没有session数据
    const { saName, moreSaName } = this.getSaName(value)
    if (!(saName || moreSaName)) return false // 不在埋点列表中
    this.handleSaNameLimitData({ saName, moreSaName })
    
    let { activity_name, activity_param = {}, page_name, page_param, timestamp, end_time } = value

    // 处理时间戳长度
    if (this.timestampLength && timestamp) {
      timestamp = +(`${timestamp}`.slice(0, this.timestampLength)) || 0
      if (end_time) {
        end_time = +(`${end_time}`.slice(0, this.timestampLength)) || 0
      }
    }
    const setSaNameData = ({  saName: key } = {}) => {
      if (!key) return
      if (!objData[key]) {
        objData[key] = []
      }
      const activityParam = Object.assign({}, activity_param || {})
      const _actionId = activityParam?._actionId
      const param = {
        activity_name,
        activity_param: {
          ...activityParam,
          _actionId,
        },
        page_param: Object.assign({}, page_param),
        page_name,
        timestamp,
        end_time
      }
      const _param = this.handleAction ? this.handleAction(param, this.data, { result, config: this.config }) : param
      // 当传递了_actionId值，那返回的数据应该是一个对象，并且有_actionId字段
      this.handleWarnValidateData(_param, { _actionId })

      _param && objData[key].push(_param)
    }
    ;[moreSaName, saName].forEach(item => setSaNameData(item))
    this.onWatch(result, value)
  }

  handleWarnValidateData(data, { _actionId }) {
    if (!_actionId) return
    // 必须返回data为对象格式
    if (!data || typeof data !== 'object') {
      console.warn('传递_actionId时，返回的数据必须是对象格式!')
      return
    }
    // 必须返回字段，activity_name, activity_param，_actionId
    if (!data.activity_name || !data.activity_param || !data.activity_param._actionId) {
      console.warn('返回的数据必须包含activity_name, activity_param，_actionId字段')
    }
  }

  onConfigMerge(config) {
    if (!config) return
    this.config = Object.assign(this.config, config)
    this._init()
  }
}

export { CollectData }
