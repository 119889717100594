const CLICK_GOODS_SA_NAME = 'click_goods_list' // 点击商品
const DELETE_GOODS_SA_NAME = 'click_goods_delete' // 删除商品
const BATCH_DELETE_GOODS_SA_NAME = 'click_deleteproductsyes' // 批量删除

const getSortData = (data = []) => {
  return data?.sort((a, b) => b.timestamp - a.timestamp) || []
}

const config = {
  featureKey: 'cart_v2',
  closeSessionStorage: false,
  saNames: [
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 20
    },
    {
      saName: DELETE_GOODS_SA_NAME,
      limit: 20
    },
    {
      saName: BATCH_DELETE_GOODS_SA_NAME,
      limit: 20
    },
  ],
  handleAction(data, collectData, { config }) {
    // 触发埋点时执行
    const { triggerType = 'off' } = config
    const { activity_name, activity_param, timestamp } = data
    if (['all', 'click'].includes(triggerType) && [CLICK_GOODS_SA_NAME].includes(activity_name)) {
      return { activity_name, activity_param, timestamp: Number(timestamp) }
    } 
    if (['all', 'delete'].includes(triggerType) && [DELETE_GOODS_SA_NAME, BATCH_DELETE_GOODS_SA_NAME].includes(activity_name)) {
      return { activity_name, activity_param, timestamp: Number(timestamp) }
    }
  },
  onTrigger({ collectData }) {
    // 获取收集数据时执行，return出最终收集到的数据
    const result = Object.values(collectData)[0] || {}
    const clickSaData = getSortData(result[CLICK_GOODS_SA_NAME] || [])
    const deleteSaData = getSortData(result[DELETE_GOODS_SA_NAME] || [])
    const batchDeleteSaData = getSortData(result[BATCH_DELETE_GOODS_SA_NAME] || [])

    let clickArr = []
    let deleteArr = []

    clickSaData.forEach(i => { clickArr.push(i.activity_param?.rt_f_cart_goods_id) })
    deleteSaData.forEach(i => { deleteArr.push(i.activity_param?.rt_f_cart_goods_id) })
    batchDeleteSaData.forEach(i => {
      const delete_goods_ids = i.activity_param?.rt_f_cart_goods_id.split(',')
      deleteArr = deleteArr.concat(delete_goods_ids)
    })

    // 从session中获取上次刷新之前记录到的数据，拼接到本次记录的数据后面（产品要求逆序）
    const {
      rt_f_cart_clk = '',
      rt_f_cart_delete = ''
    } = JSON.parse(window.sessionStorage.getItem('cart_v2_track_info_before_refresh')) || {}
    if (rt_f_cart_clk) clickArr = clickArr.concat(rt_f_cart_clk?.split(','))
    if (rt_f_cart_delete) deleteArr = deleteArr.concat(rt_f_cart_delete?.split(','))

    return {
      rec_ctx: {
        rt_f_cart_clk: [...new Set(clickArr)].join(','),
        rt_f_cart_delete: [...new Set(deleteArr)].join(','),
        rt_f_cart_select: window.sessionStorage.getItem('cart_v2_track_info_select') || '',
      }
    }
  }
}

export default config
