/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import { getCookie } from '@shein/common-function'
// 本地存储key
export const COOKIE_KEY = 'memberId'

export const getMemeberId = () => {
  return getCookie(COOKIE_KEY) || ''
}


