/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */
import UserKey from '../UserKey'
// import { getCookie, setCookie, getQueryString } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { setLocalStorage } from '@shein/common-function'
import { getCurrency } from './lite/CurrentcyLite'

// const { default_currency, currencies, lang } = gbCommonInfo
const COOKIE_KEY = 'currency'
const MAX_AGE = 1000 * 60 * 60 * 24 * 30  //30天

class Currency extends UserKey {
  static canHandle(key) {
    return key === 'currency'
  }

  get(options) {
    return getCurrency(options)
  }
  async set(value, { params = {} } = {}) {
    Object.assign(params, {
      currency: value
    })

    //提供给用增游戏等其它pwa项目获取货币
    setLocalStorage({ key: COOKIE_KEY, value: value, expire: MAX_AGE })

    const res = await schttp({
      url: `/api/common/currency/update`,
      params
    })
    return { value, res }
  }
}

export default Currency
