import { LIST_PAGE_PATHS } from 'public/src/pages/product_app/router/constants.js'

const CLICK_GOODS_SA_NAME = 'click_goods_list'
const CLICK_ADD_BAG_SA_NAME = 'click_add_bag'

const getSortData = (data = []) => {
  const arr = []
  data.sort((a, b) => b.timestamp - a.timestamp).forEach(_ => arr.push(_.goods_id, _.timestamp))
  return arr
}

const handleClickAction = (result) => {
  return getSortData(result[CLICK_GOODS_SA_NAME] || [])
}

const handleAddCartAction = (result) => {
  return getSortData(result[CLICK_ADD_BAG_SA_NAME] || [])
}

const config = {
  featureKey: 'click_refresh', // 场景key
  singleKey: true, // 是否单例，如果是单例，那么只会有一个sessionId
  closeSessionStorage: true, // 是否使用 sessionStorage 存储数据
  diffTargetUrlClear: true, // 目标的url和开始的url匹配不上就清空数据
  timestampLength: 10, // 时间戳长度配置
  saNames: [
    {
      saName: CLICK_GOODS_SA_NAME,
      limit: 40
    },
    {
      saName: CLICK_ADD_BAG_SA_NAME,
      limit: 40
    },
  ], 
  // 所经过的埋点name
  targetNames: [
    {
      name: 'product-list-v2',
      url: `/${LIST_PAGE_PATHS.join('|')}`,
    },
    {
      name: 'shein-picks',
      url: `/[a-z0-9]+-picks\\.html`,
    }
  ],
  // 清空状态clearHandle回调： route 当前路由，clearDiffState 清空状态方法
  clearHandle({ route = {}, clearDiffState }) {
    if (route.to?.name !== 'shein-picks') return clearDiffState
    // 判断上一个参数和下一个参数是否一致。
    // https://m.shein.com/us/shein-picks.html?hrefTarget=668&src_module=all&
    // 参数hrefTarge变了就返回true,其他返回false
    if (!this._clearQueryFlag) {
      this._clearQueryFlag = route?.to?.query?.hrefTarget
      return false
    }
    const flag = this._clearQueryFlag !== route.to?.query?.hrefTarget
    this._clearQueryFlag = route.to?.query?.hrefTarget
    return flag
  }, 
  handleAction(data) { // 过滤数据, 每次埋点都会触发
    const { activity_name, activity_param, timestamp, page_name, page_param } = data
    const actionListPageNameArr = ['page_real_class', 'page_select_class', 'page_goods_group']
    const listNameInIdentifier = ['real', 'itemPicking', 'sheinPicks']
    const isGoodsDetail = page_name === 'page_goods_detail'
    const isFromList = listNameInIdentifier.find(listName => page_param?.src_identifier?.includes(listName)) // 从真实，选品，picks页进入商详

    // 点击商卡行为，在真实、选品、picks页时，才收集
    if (activity_name === 'click_goods_list' && actionListPageNameArr.includes(page_name)) {
      const goods_id = activity_param.goods_list.split('`')?.[0] || ''
      if(!goods_id) return null

      // return [Number(goods_id), Number(timestamp)]
      return {
        activity_name, // 必须返回字段
        activity_param: { 
          _actionId: activity_param._actionId
        }, // 必须返回字段
        goods_id: Number(goods_id),
        timestamp: Number(timestamp)
      }
    }

    // 加车行为，在真实、选品页、picks页和商详主商品时，才收集
    if (activity_name === 'click_add_bag') {
      
      const isMainDetailAddBag = activity_param?.activity_from == 'main' // 商详主商品接车
      if (!(actionListPageNameArr.includes(page_name) || isFromList && isGoodsDetail && isMainDetailAddBag)) return null

      const goods_id = activity_param.goods_id || ''
      if(!goods_id) return null

      // return [Number(goods_id), Number(timestamp)]
      return {
        activity_name,
        activity_param: { 
          _actionId: activity_param._actionId
        },
        goods_id: Number(goods_id),
        timestamp: Number(timestamp)
      }
    }

    return null
  },
  onTrigger({ collectData }) {
    const result = Object.values(collectData)[0] || {}
    const clickAction = handleClickAction(result)
    const addCartAction = handleAddCartAction(result)

    return {
      rec_user: {
        100017: clickAction, // 搜索结果页点击行为
        100018: addCartAction, // 搜索结果页加车行为
      }
    }
  }
}

export default config
