import { DETAIL_PAGE_PATHS } from 'public/src/pages/product_app/router/constants.js'
import { getQueryString } from '@shein/common-function'

const handleClickAddBag = (data) => {
  return {
    goods_id: Number(data?.activity_param?.goods_id || ''),
    timestamp: Number(data?.timestamp?.slice?.(0, 10) || ''),
  }
}

const handleClickGoodsList = (data) => {
  return {
    goods_id: Number((data?.activity_param?.goods_list || '')?.split('`')?.[0] || ''),
    timestamp: Number(data?.timestamp?.slice?.(0, 10) || ''),
  }
}

const featureKey = 'trend_landing'

export default {
  featureKey, // 特征 key
  singleKey: true, // 单个 sessionId
  closeSessionStorage: true, // 不使用 sessionStorage 存储数据
  saNames: [
    {
      saName: 'click_add_bag',
      limit: 40,
    },
    {
      saName: 'click_goods_list',
      limit: 40,
    },
  ],
  targetNames: [
    {
      name: 'trend_landing',
      url: '/trend-landing'
    },
    {
      name: 'page_goods_detail',
      url: `/${DETAIL_PAGE_PATHS.join('|')}`,
    },
  ],
  handleAction(data) {
    // 通过路由信息判断
    const [{ from }] = window.landingTrendRouterTracker || [{}]
    const isFromTrendLanding = from?.name === 'trend_landing' || getQueryString({ 'key': 'entry_from' }) === 'page_trend_landing'
    const { activity_name, activity_param, page_name } = data
    // 仅处理趋势页点击商品、加车和从趋势页进入商详的加车
    if (
      !((page_name === 'page_trend_landing' && ['click_add_bag', 'click_goods_list'].includes(activity_name))
      ||
      (page_name === 'page_goods_detail' && isFromTrendLanding && activity_name === 'click_add_bag' && activity_param?.activity_from === 'main' && activity_param?.result === '1'))
    ) {
      return false
    }
    switch (activity_name) {
      case 'click_goods_list':
        return handleClickGoodsList(data)
      case 'click_add_bag':
        return handleClickAddBag(data)
      default:
        return false
    }
  },
  onTrigger({ collectData }) {
    const { click_goods_list, click_add_bag } = Object.values(collectData)[0] || {}
    const rt_f_list_cur_clk = [...(click_goods_list || [])]?.reverse?.() || []
    const rt_f_list_cur_cart = [...(click_add_bag || [])]?.reverse?.() || []
    return {
      rec_user: {
        100017: rt_f_list_cur_clk, // 搜索结果页点击行为
        100018: rt_f_list_cur_cart, // 搜索结果页加车行为
      },
    }
  }
}
